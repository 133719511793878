var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "出库记录",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-search', {
    attrs: {
      "clearable": "",
      "placeholder": "商品名称"
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.search_key,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "search_key", $$v);
      },
      expression: "listQuery.search_key"
    }
  })], 1), _c('div', {
    staticClass: "app-main-container list"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (transOrder, index) {
    return _c('div', {
      key: index,
      staticClass: "accept-item",
      on: {
        "click": function click($event) {
          return _vm.toDetailView(transOrder.id);
        }
      }
    }, [_c('van-cell', {
      attrs: {
        "title": "出库人",
        "value": transOrder.outer.name
      }
    }), _c('van-cell', {
      attrs: {
        "title": "出库时间",
        "value": transOrder.out_datetime
      }
    }), _c('van-cell', {
      attrs: {
        "title": "".concat(transOrder.from_store.name, "\u2192").concat(transOrder.to_store.name),
        "value": transOrder.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "".concat(transOrder.kind, "\u79CD\u5546\u54C1"),
        "value": "\u5171\uFFE5".concat(transOrder.cost_amount)
      }
    })], 1);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }