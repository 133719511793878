import request from '@/utils/request'

export function getTransOuts() {
  return request({
    method: 'get',
    url: 'trans-out'
  })
}

export function getTransOutDetail(transOrderId) {
  return request({
    method: 'get',
    url: `trans-out/${transOrderId}`
  })
}

export function finishTransOut(transOrderId) {
  return request({
    method: 'post',
    url: `trans-out/${transOrderId}`
  })
}

export function getBusinessOrderDetail(params) {
  return request({
    method: 'get',
    url: `trans-out/business-order-detail`,
    params
  })
}

export function businessOrderGoodsConfirm(data) {
  return request({
    method: 'post',
    url: `trans-out/business-confirm`,
    data
  })
}

export function finishBusinessOrder() {
  return request({
    method: 'post',
    url: `trans-out/business-finished`
  })
}

export function getTransOutRecords(params) {
  return request({
    method: 'get',
    url: 'trans-out/record',
    params
  })
}

export function getTransOutRecordDetail(transOrderId) {
  return request({
    method: 'get',
    url: `trans-out/record/${transOrderId}`
  })
}

export function transGoodsOutConfirm(data) {
  return request({
    method: 'post',
    url: `trans-out/confirm`,
    data
  })
}
